import { Pagination } from "react-bootstrap"

export default function CustomPagination({ reqResponse, setCurrentPage, currentPage }) {
    const noOfItemsPerPage = 10
    const noOfPages = Math.ceil(reqResponse.count / noOfItemsPerPage)
    const noOfPagesShown = 2
    let items = [];

    for (let number = 1; number <= noOfPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => setCurrentPage(number)}>
                {number}
            </Pagination.Item>
        );
    }


    return (<Pagination>
        <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
        <Pagination>{items}</Pagination>
        <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={reqResponse.next === null} />
        <Pagination.Last onClick={() => setCurrentPage(noOfPages)} disabled={currentPage === noOfPages} />
    </Pagination>)
}