import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClientProvider } from '@tanstack/react-query';

import RootLayout from "./pages/Root";
import ErrorPage from "./pages/Error";
import HomePage from "./pages/Home";
import ForgotPassword, { action as ForgotPasswordAction } from "./pages/ForgotPassword.js";
import ConfirmEmail from "./pages/ConfirmEmail.js";
import Register, { action as RegistrationAction } from "./pages/Register";
import LoginPage, { action as LoginAuthAction } from "./pages/Login";
import HistoryPage from "./pages/History";
import EmailPage from "./pages/Emails";
import ContractCreation from './pages/ContractCreation.js'
import GetExternalPassword from "./pages/GetExternalPassword.js";
import PasswordReset, { action as PasswordResetAction } from "./pages/PasswordReset.js";
import ResendConfirmationEmail, { action as ResendConfirmationEmailAction } from "./pages/ResendConfirmationEmail.js";
import { action as logoutAction } from "./pages/Logout";
import { tokenLoader, checkAuthToken as checkAuthTokenLoader } from "./util/auth";
import { queryClient } from './util/http.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import History2 from "./pages/History2.js";


const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    loader: tokenLoader,
    id: 'root',
    children: [
      { index: true, element: <HomePage /> },
      { path: '/login', action: LoginAuthAction, element: <LoginPage /> },
      { path: '/register', element: <Register />, action: RegistrationAction },
      { path: '/logout', action: logoutAction },
      { path: '/emails', element: <EmailPage />, loader: checkAuthTokenLoader },
      { path: '/history', element: <HistoryPage />, loader: checkAuthTokenLoader },
      { path: '/external_passwort', element: <GetExternalPassword />, loader: checkAuthTokenLoader },
      { path: '/contract_creation', element: <ContractCreation />, loader: checkAuthTokenLoader },
      { path: '/account-confirm-email/:email_id', element: <ConfirmEmail /> },
      { path: '/forgot_password', element: <ForgotPassword />, action: ForgotPasswordAction },
      { path: '/resend_confirmation_email', element: <ResendConfirmationEmail />, action: ResendConfirmationEmailAction },
      { path: '/password_reset/:uid/:token/', element: <PasswordReset />, action: PasswordResetAction },

      { path: '/history2', element: <History2 />, loader: checkAuthTokenLoader }
    ],
  },
]);

function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
