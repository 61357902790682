import { useQuery, useMutation } from "@tanstack/react-query";
import React, { useState, useRef, useEffect } from "react";
import { Button, Overlay, Alert, Badge, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import Table from 'react-bootstrap/Table';

import ExternalPasswordModal from "../components/ExternalPasswordModal";
import { scrollToTop } from "../util/utils";
import { fetchExternalEnrolledCompanies, queryClient, fetchDeleteEnrolledCompany, fetchRefreshCodeEnrolledCompany } from "../util/http";

import './External.css'

export default function GetExternalPassword() {
    const [allertShow, setAllertShow] = useState(false)
    const [allertVariant, setAllertVariant] = useState("success")
    const [allertContent, setAllertContent] = useState("Oops")
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const { data: enrolledCompanies, isError: enrolledCompaniesIsError, error: enrolledCompaniesError } = useQuery({
        queryKey: ['enrolledCompanies'],
        queryFn: ({ signal }) => fetchExternalEnrolledCompanies({ signal }),
    });

    useEffect(() => {
        if (allertShow) {
            scrollToTop();
            setTimeout(() => {
                setAllertShow(false);
            }, 2500);
        }
    }, [allertShow])

    function copyToClipboard(text) {
        setShow(!show)
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
            return //codes below wont be executed
        }
        const textArea = document.createElement("textarea")
        textArea.value = text

        document.body.appendChild(textArea)

        textArea.focus()
        textArea.select()

        // Depricated method used only by older browser versions 
        document.execCommand('copy')

        document.body.removeChild(textArea)
    }

    const { mutate: refreshCompanyId } = useMutation({
        mutationFn: fetchRefreshCodeEnrolledCompany,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['enrolledCompanies'] });
        },
        onError: () => {
            setAllertVariant("danger")
            setAllertContent("Code refresh has failed")
            setAllertShow(true)
        }
    });

    const { mutate: deleteCompanyId, isPending: updatePending } = useMutation({
        mutationFn: fetchDeleteEnrolledCompany,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['enrolledCompanies'] });
        },
        onError: () => {
            setAllertVariant("danger")
            setAllertContent("Das Löschen des Kuden ist fehlgeschlagen!")
            setAllertShow(true)
        }
    });

    function deleteCompany(id, is_active) {
        deleteCompanyId({ id, is_active })
    }

    function refreshCompanyCode(id) {
        refreshCompanyId({ id })
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                In die Zwischenablage kopiert
            </Popover.Body>
        </Popover>
    );

    const renderTooltip = (message) => (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {message}
        </Tooltip>
    );

    function listEnrolledCompanies() {
        if (!enrolledCompanies) return <><h1>No Entries Found</h1></>
        return (
            <>
                <Table striped bordered hover size="sm" >
                    <thead>
                        <tr>
                            <th>Nummer</th>
                            <th>Kurzname/Sortiername</th>
                            <th>Name</th>
                            <th>Strasse</th>
                            <th>Land</th>
                            <th>PLZ</th>
                            <th>Ort</th>
                            <th>Bundesstaat</th>
                            <th>Passwort</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {enrolledCompanies.map((row) => (
                            <tr key={row.id}>
                                <td>{row.client_number}</td>
                                <td>{row.short_name}</td>
                                <td>{row.name}</td>
                                <td>{row.street}</td>
                                <td>{row.country}</td>
                                <td>{row.plz}</td>
                                <td>{row.ort}</td>
                                <td>{row.state}</td>
                                <td>{row.email_code}</td>
                                <td>
                                    <Badge bg={row.is_active ? "success" : "danger"} pill>{row.is_active % 2 ? "Aktiv" : "Deaktiviert"}</Badge>
                                </td>
                                <td>
                                    <OverlayTrigger trigger="click" placement="auto" overlay={popover} rootClose>
                                        <Button variant="outline-primary" ref={target} onClick={() => copyToClipboard(row.email_code)}><img src='/copy.png' alt="Kopieren" /></Button>
                                    </OverlayTrigger>
                                </td>
                                <td>
                                    <ExternalPasswordModal ButtonName={<img src='/edit.png' alt="Bearbeiten" />} EditData={row} EditFlag={true} setAllertContent={setAllertContent} setAllertVariant={setAllertVariant} setAllertShow={setAllertShow} tooltipMessage={"Bearbeiten"} />
                                </td>
                                <td>
                                    <OverlayTrigger placement="auto" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("Passwort aktualisieren")}>
                                        <Button variant="outline-primary" onClick={() => refreshCompanyCode(row.id)}><img src='/refresh.png' alt="Passwort aktualisieren" /></Button>
                                    </OverlayTrigger>
                                </td>
                                <td>
                                    <OverlayTrigger placement="auto" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("deaktivieren")}>
                                        <Button variant="outline-danger" onClick={() => deleteCompany(row.id, row.is_active)} ><img src={row.is_active ? '/clear.png' : '/check.png'} alt="Löschen" /></Button>
                                    </OverlayTrigger></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </>
        );
    }


    return (
        <>
            <Alert key={allertVariant} variant={allertVariant} show={allertShow}>
                {allertContent}
            </Alert>
            {listEnrolledCompanies()}
            <ExternalPasswordModal ButtonName={"Neuen Kunden erstellen"} setAllertContent={setAllertContent} setAllertVariant={setAllertVariant} setAllertShow={setAllertShow} tooltipMessage={"Neueintrag"} />
        </>

    )
}

