import { useState } from "react";
import { useQuery, useMutation } from '@tanstack/react-query';
import Button from 'react-bootstrap/Button';

import ErrorBlock from "./ErrorBlock";
import LoadingIndicator from "./LoadingIndicator";
import CompleteEmailForm from "./CompleteEmailForm";
import AlertDismissible from "./AlertDismissible";
import { scrollToTop } from "../util/utils";
import './CompleteEmail.css'
import { fetchEmailsPrediction, fetchAuftragscodes, createCompleteEmail, fetchGenerateXml, queryClient } from "../util/http";


export default function EmailForm({ selectedEmail }) {
    const [formCount, setFormCount] = useState(1);
    const [submitForm, setSubmitForm] = useState(false);
    const [show, setShow] = useState(false);
    const [alertVariant, setAlertVariant] = useState('success');
    const [pageContext, setPageContext] = useState('CompleteEmails')
    let content = null;

    const { mutate, isPending: MutationPending } = useMutation({
        mutationFn: createCompleteEmail,
        onSuccess: (data) => {
            setPageContext('CompleteEmails');
            setShow(true);
            setAlertVariant('success');
            scrollToTop();
            // submit form as well, if submitForm flag is active
            if (submitForm) { handleFormSubmit(data.id) }
        },
        onError: () => {
            setPageContext('CompleteEmails');
            setShow(true);
            setAlertVariant('danger');
            scrollToTop();
        }
    });

    const { mutate: submitFormMutation, isPending: subntMutationPending } = useMutation({
        mutationFn: fetchGenerateXml,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['emails'] });
            setPageContext('CompletedEmail');
            setShow(true);
            setAlertVariant('success');
            scrollToTop();
        },
        onError: () => {
            setPageContext('CompletedEmail');
            setShow(true);
            setAlertVariant('danger');
            scrollToTop();
        }
    });

    function handleFormSubmit(id) {
        const answear = window.confirm("Sie sind dabei, das Formular abzusenden. Sind Sie sicher?")
        if (answear) {
            submitFormMutation({ id })
        }
    }

    const { data: auftragscodesData, isPending: auftragscodesIsPending, isError: auftragscodesIsError, error: auftragscodesError } = useQuery({
        queryKey: ['auftragscodes'],
        queryFn: ({ signal }) => fetchAuftragscodes({ signal }),
    });

    const { data, isPending, isError, error } = useQuery({
        queryKey: ['emails', { id: selectedEmail.id }],
        queryFn: ({ signal, queryKey }) => fetchEmailsPrediction({ signal, ...queryKey[1] }),
        staleTime: 120000, // 2 minutes
        // gcTime: 1000
    });

    if (isPending || auftragscodesIsPending) {
        content = <LoadingIndicator />;
    }

    if (isError) {
        content = (
            <ErrorBlock
                title="An error occurred"
                message={error.info?.prediction.message || 'Failed to fetch email predictions.'}
            />
        );
    }
    if (auftragscodesIsError) {
        content = (
            <ErrorBlock
                title="An error occurred"
                message={auftragscodesError.info?.prediction.message || 'Failed to fetch auftragscodes.'}
            />
        );
    }

    const addForm = () => {
        setFormCount(prevCount => prevCount + 1);
    };
    const deleteForm = () => {
        if (formCount > 1) {
            setFormCount(prevCount => prevCount - 1);
        }
    };

    function handleSubmit(formData) {
        const answear = window.confirm("Sie sind dabei, das Formular zu speichern. Sind Sie sicher?")
        if (answear) {
            const jsonWaognsWeights = formData.wagons.map((wagon) => { return wagon.gewicht })
            const jsonWagonsProducts = formData.wagons.map((wagon) => { return wagon.produkt })
            const jsonWagonsWagonNr = formData.wagons.map((wagon) => { return wagon.wagon_nr })
            const jsonWagonsMrn = formData.wagons.map((wagon) => { return wagon.mrn })
            const lieferant_nr_buffer = formData.lieferant_nr.replace(/\s/g, '') === '' ? 'Keine Nummer!' : formData.lieferant_nr;
            const zulauf_nr_buffer = formData.zulauf_nr.replace(/\s/g, '') === '' ? 'Keine Nummer!' : formData.zulauf_nr;

            const correctedEmailForm = {
                email_uuid: data.email_uuid,
                weight: '[' + jsonWaognsWeights + ']',
                product: '[' + jsonWagonsProducts + ']',
                transport_nr: '[' + jsonWagonsWagonNr + ']',
                mrn: '[' + jsonWagonsMrn + ']',
                counterpart: formData.counterpart,
                remarks: formData.remarks,
                deliver_place: formData.deliver_place,
                date_load: formData.date_load,
                auftrags_code: formData.auftrags_code,
                zulauf_nr: zulauf_nr_buffer,
                lieferant_nr: lieferant_nr_buffer,
                station_code: formData.vbf_nr,
                weighing_station: formData.weighing_station
            }
            mutate(correctedEmailForm);
        }
    }

    function submitFormOption() {
        // set the submit the form flag
        setSubmitForm(true)
    }
    function saveFormOption() {
        // set the submit the form flag
        setSubmitForm(false)
    }

    if (data && auftragscodesData) {
        content = (
            <div className="scrollable_div">
                {[...Array(formCount)].map((_, index) => (
                    <CompleteEmailForm key={index} onSubmit={handleSubmit} inputData={data} auftragscodesData={auftragscodesData}>
                        <Button onClick={saveFormOption} type="submit" variant="success" disabled={MutationPending}>{MutationPending ? 'Speichern...' : 'Formular Speichern'}</Button>
                        <Button onClick={submitFormOption} type="submit" variant="outline-success" disabled={subntMutationPending}>{subntMutationPending ? 'Senden...' : 'Formular absenden'}</Button>
                    </CompleteEmailForm>
                ))}
                <div>
                    <button type="button" className="btn btn-primary " onClick={addForm}>Weiteres Formular hinzufügen</button>
                    <button type="button" className="btn btn-secondary " disabled={formCount === 1} onClick={deleteForm}>Formular löschen</button>
                </div>
            </div>
        )
    }

    return (
        <>
            <AlertDismissible show={show} setShow={setShow} variant={alertVariant} pageContext={pageContext} />
            {content}
        </>
    );
}