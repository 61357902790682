import { useState, useEffect } from 'react';

import './CompleteEmailFormWagons.css'

export default function CompleteEmailFormWagons({ formData, handleChange, index_: index, children, mrn_field = true }) {

    const [weightError, setWeightError] = useState()
    const [wagonNrError, setWagonNrError] = useState()
    const [productError, setProductError] = useState()


    function commaValidator(value, setValue) {
        !(value.includes(',')) ? setValue() : setValue("Komma ist nicht erlaubt!")
    }
    function validator(value, setValue) {
        (/^\d+$/.test(value)) ? setValue() : setValue("Nur Zahlen erlaubt!")
    }

    useEffect(() => {
        validator(formData.wagon_nr, setWagonNrError);
        validator(formData.gewicht, setWeightError);
        commaValidator(formData.produkt, setProductError);
    }, [])

    return (
        <div className="input-container">
            <h5>----- Wagenformular ------</h5>
            <div>
                <label >Wagonnummer: </label>
                <input
                    type="text"
                    name='wagon_nr'
                    value={formData.wagon_nr}
                    required={true}
                    onChange={(event) => { validator(event.target.value, setWagonNrError); handleChange(index, event); }}
                    onBlur={() => validator(formData.wagon_nr, setWagonNrError)}
                />
            </div>
            <div>{wagonNrError &&
                <ul>
                    <li key={wagonNrError} style={{ color: 'red' }} >{wagonNrError}</li>
                </ul>
            }
            </div>

            <div>
                <label >Gewicht (Kg):</label>
                <input
                    type="text"
                    name="gewicht"
                    value={formData.gewicht}
                    required={true}
                    onChange={(event) => { validator(event.target.value, setWeightError); handleChange(index, event); }}
                    onBlur={() => validator(formData.gewicht, setWeightError)}
                />
            </div>
            <div>{weightError &&
                <ul>
                    <li key={weightError} style={{ color: 'red' }} >{weightError}</li>
                </ul>
            }
            </div>

            <div>
                <label >Sorte:</label>
                <textarea
                    className='wagon_textarea'
                    name="produkt"
                    value={formData.produkt}
                    required={true}
                    onChange={(event) => { commaValidator(event.target.value, setProductError); handleChange(index, event); }}
                    onBlur={() => commaValidator(formData.produkt, setProductError)}
                />
            </div>
            <div>{productError &&
                <ul>
                    <li key={productError} style={{ color: 'red' }} >{productError}</li>
                </ul>
            }
            </div>

            {mrn_field && <div>
                <label >Mrn (Optional):</label>
                <input
                    type="text"
                    name="mrn"
                    value={formData.mrn}
                    required={false}
                    onChange={(event) => { handleChange(index, event); }}
                />
            </div>}
            {children}
        </div>
    )
}