import { useState } from "react";

import { Modal, CloseButton, Button } from "react-bootstrap";

export default function HistoryModal({ updateMutate, xmlUpdateMutate, setShowModal, formData, setFormData, showModal }) {
    const handleClose = () => {
        setFormData(null)
        setShowModal(false)
    };

    function handleChange(index, event) {
        const { name, value } = event.target;
        const wagons = [...formData.wagons];
        wagons[index][name] = value;
        setFormData({ ...formData, wagons: wagons });
    };

    function handleUpdate() {
        const jsonWaognsWeights = formData.wagons.map((wagon) => { return wagon.gewicht })
        const jsonWagonsProducts = formData.wagons.map((wagon) => { return wagon.produkt })
        const jsonWagonsWagonNr = formData.wagons.map((wagon) => { return wagon.wagon_nr })
        const jsonWagonsMrn = formData.wagons.map((wagon) => { return wagon.mrn })
        const id = formData.id


        const updateForm = {
            weight: '[' + jsonWaognsWeights + ']',
            product: '[' + jsonWagonsProducts + ']',
            transport_nr: '[' + jsonWagonsWagonNr + ']',
            mrn: '[' + jsonWagonsMrn + ']',
            counterpart: formData?.counterpart,
            remarks: formData?.remarks,
            deliver_place: formData?.deliver_place,
            date_load: formData?.date_load,
            auftrags_code: formData?.auftrags_code,
            zulauf_nr: formData?.zulauf_nr,
            lieferant_nr: formData?.lieferant_nr,
            station_code: formData?.station_code,
            weighing_station: formData?.weighing_station,
        }

        if (formData.xmlFlag) {
            xmlUpdateMutate(updateForm, id)
        }
        else {
            updateMutate(updateForm, id);
        }
    }
    if (formData) {
        return (
            <Modal show={showModal} onHide={handleClose}>
                <CloseButton className='rightmost-corner-element' onClick={handleClose} />

                <div className='completed_email_form'>
                    <div className='completed_email_card'>
                        <div className='grey-text bot-gap'><span className='title'> Empfänger:</span>
                            <input
                                type="text"
                                name="counterpart"
                                value={formData.counterpart}
                                required={true}
                                onChange={(event) => setFormData({ ...formData, counterpart: event.target.value })}
                            />
                        </div>
                        <div className='grey-text bot-gap'><span className='title'> Vermerke:</span>
                            <textarea
                                name="remarks"
                                className='remarks_textarea'
                                value={formData.remarks}
                                required={false}
                                onChange={(event) => setFormData({ ...formData, remarks: event.target.value })}
                            />
                        </div>
                        <div className='grey-text'><span className='title'>Lieferort: </span>
                            <input
                                type="text"
                                name="deliver_place"
                                value={formData.deliver_place}
                                required={true}
                                onChange={(event) => setFormData({ ...formData, deliver_place: event.target.value })}
                            />
                        </div>
                        <div className='grey-text'><span className='title'>Datum geladen: </span>
                            <input
                                type="datetime-local"
                                name="date_load"
                                value={formData.date_load}
                                required={true}
                                onChange={(event) => setFormData({ ...formData, date_load: event.target.value })}
                            />
                        </div>
                        <div className='grey-text'><span className='title'>Auftrags Code: </span>
                            <input
                                type="text"
                                name="auftrags_code"
                                value={formData.auftrags_code}
                                required={true}
                                onChange={(event) => setFormData({ ...formData, auftrags_code: event.target.value })}
                            />
                        </div>
                        <div className='grey-text'><span className='title'>Verwiegung Bahnhof Code: </span>
                            <input
                                type="text"
                                name="weighing_station"
                                value={formData.weighing_station}
                                required={false}
                                onChange={(event) => setFormData({ ...formData, weighing_station: event.target.value })}
                            />
                        </div>
                        <div className='grey-text'><span className='title'>Zulaufnummer: </span>
                            <input
                                type="text"
                                name="zulauf_nr"
                                value={formData.zulauf_nr}
                                required={true}
                                onChange={(event) => setFormData({ ...formData, zulauf_nr: event.target.value })}
                            />
                        </div>
                        <div className='grey-text'><span className='title'>Lieferantnummer: </span>
                            <input
                                type="text"
                                name="lieferant_nr"
                                value={formData.lieferant_nr}
                                required={true}
                                onChange={(event) => setFormData({ ...formData, lieferant_nr: event.target.value })}
                            />
                        </div>
                        <div className='grey-text'><span className='title'>Versandbahnhof Nr.: </span>
                            <input
                                type="text"
                                name="station_code"
                                value={formData.station_code}
                                required={true}
                                onChange={(event) => setFormData({ ...formData, station_code: event.target.value })}
                            />
                        </div>

                        <div className='grey-text'><span className='title'>Erstellt am: </span> </div>

                        {formData.wagons.map((wagon, index) => (

                            <div key={index} className='bot-gap completed_wagon_form'>
                                <div className='bold'>Wagon {index + 1}:</div>
                                <div><span className='grey-text'><span className='title'>Wagonummer: </span>
                                    <input
                                        type="text"
                                        name="wagon_nr"
                                        value={wagon.wagon_nr}
                                        required={true}
                                        onChange={(event) => handleChange(index, event)}
                                    />
                                </span></div>
                                <div><span className='grey-text'><span className='title'>Sorte: </span>
                                    <input
                                        type="text"
                                        name="produkt"
                                        value={wagon.produkt}
                                        required={true}
                                        onChange={(event) => handleChange(index, event)}
                                    />
                                </span></div>
                                <div><span className='grey-text'><span className='title'>Gewicht: </span>
                                    <input
                                        type="text"
                                        name="gewicht"
                                        value={wagon.gewicht}
                                        required={true}
                                        onChange={(event) => handleChange(index, event)}
                                    />
                                </span></div>
                                <div><span className='grey-text'><span className='title'>Mrn: </span>
                                    <input
                                        type="text"
                                        name="mrn"
                                        value={wagon.mrn}
                                        required={false}
                                        onChange={(event) => handleChange(index, event)}
                                    />
                                </span></div>

                            </div>
                        ))}
                    </div>
                </div>
                <Button variant="primary" onClick={handleUpdate}>Aktualisieren</Button>
                <div className='bot-gap' />
                <Button variant="outline-danger" onClick={handleClose}>Abbrechen</Button>
            </Modal>
        );
    }
}