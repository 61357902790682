import { useState, Fragment, useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ButtonGroup, ToggleButton, Table, Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


import { fetchAllCorretedEmails, fetchDeleteForm, fetchUpdateForm, queryClient, fetchGenerateXml, fetchGenerateXmlUpdate, fetchGenerateXmlCancel, fetchDownloadExcel } from '../util/http';
import LoadingIndicator from '../components/LoadingIndicator';
import ErrorBlock from '../components/ErrorBlock';
import HistoryModal from '../components/HistoryModal';
import AlertDismissible from '../components/AlertDismissible';
import CustomPagination from '../components/CustomPagination';
import { scrollToTop } from '../util/utils';

export default function History2() {
    const [filterValue, setFilterValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false)
    const [formData, setFormData] = useState(null)
    const [pageContext, setPageContext] = useState('CompletedEmail');
    const [alertVariant, setAlertVariant] = useState('success');
    const [showAlert, setShowAlert] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const filter = [
        { name: 'All', value: '' },
        { name: 'Completed', value: 'True' },
        { name: 'Non Completed', value: 'False' },
    ];

    const { mutate: deleteMutate } = useMutation({
        mutationFn: fetchDeleteForm,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['emails'] });
            queryClient.invalidateQueries({ queryKey: ['emails-history'] });
            scrollToTop();
        },
        onError: () => {
            setPageContext('DeleteForm');
            setShowAlert(true);
            setAlertVariant('danger');
            scrollToTop();
        }
    });
    const { mutate: updateMutate, mutateAsync: updateMutateAsync } = useMutation({
        mutationFn: fetchUpdateForm,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['emails'] });
            queryClient.invalidateQueries({ queryKey: ['emails-history'] });
            setPageContext('UpdateForm');
            setAlertVariant('success');
            setShowAlert(true);
            scrollToTop();
            setShowModal(false)
        },
        onError: () => {
            setPageContext('UpdateForm');
            setAlertVariant('danger');
            setShowAlert(true);
            scrollToTop();
            setShowModal(false)
        }
    });
    const { mutate: submitMutate, isPending: mutationSubmitPending } = useMutation({
        mutationFn: fetchGenerateXml,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['emails'] });
            queryClient.invalidateQueries({ queryKey: ['emails-history'] });
            setPageContext('CompletedEmail');
            setAlertVariant('success');
            setShowAlert(true);
            scrollToTop();
        },
        onError: () => {
            setPageContext('CompletedEmail');
            setAlertVariant('danger');
            setShowAlert(true);
            scrollToTop();
        }
    });
    const { mutate: xmlUpdateMutate } = useMutation({
        mutationFn: fetchGenerateXmlUpdate,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['emails'] });
            queryClient.invalidateQueries({ queryKey: ['emails-history'] });
            setPageContext('UpdateOrder');
            setAlertVariant('success');
            setShowAlert(true);
            scrollToTop();
        },
        onError: () => {
            setPageContext('UpdateOrder');
            setAlertVariant('danger');
            setShowAlert(true);
            scrollToTop();
        }
    });
    const { mutate: xmlCancelMutate } = useMutation({
        mutationFn: fetchGenerateXmlCancel,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['emails'] });
            queryClient.invalidateQueries({ queryKey: ['emails-history'] });
            setPageContext('CancelOrder');
            setShowAlert(true);
            setAlertVariant('success');
            scrollToTop();
        },
        onError: () => {
            setPageContext('CancelOrder');
            setShowAlert(true);
            setAlertVariant('danger');
            scrollToTop();
        }
    });
    const { mutate: downloadExcelMutate } = useMutation({
        mutationFn: fetchDownloadExcel,
        onError: () => {
            setPageContext('CancelOrder');
            setShowAlert(true);
            setAlertVariant('danger');
            scrollToTop();
        }
    });


    function handleDownloadExcel() {
        downloadExcelMutate({ currentPage: currentPage, completed: filterValue, startDate: startDate, endDate: endDate, filterText: filterText });
    }
    function handleXmlCancelMutation(id) {
        const answear = window.confirm("Cancel Submited Form?")
        if (answear) {
            xmlCancelMutate({ id })
        }
    }
    function handleXmlFormUpdateModal(entry) {
        prepeareEditModal(entry, true)
    }
    async function handleXmlUpdateMutation(updateForm, id) {
        try {
            await updateMutateAsync({ updateForm, id })
            xmlUpdateMutate({ id })
        }
        catch (error) {
            console.log(error)
        }
    }
    function handleFormUpdateModal(entry) {
        prepeareEditModal(entry)
    }
    function handleFormUpdateMutation(updateForm, id) {
        updateMutate({ updateForm, id });
    }
    function handleFormSubmit(id) {
        const answear = window.confirm("Submit Form?")
        if (answear) {
            submitMutate({ id })
        }
    }
    function handleDeleteForm(id) {
        const answear = window.confirm("Sind Sie sicher, dass Sie dieses Formular löschen möchten?")
        if (answear) {
            deleteMutate({ id });
        }
    }
    const handleFilterInputChange = (event) => {
        setFilterText(event.target.value);
    };
    const { data: dataForms, isPending: isPendingForms, isError: isErrorForms, error: errorForms } = useQuery({
        queryKey: ['emails-history', { currentPage: currentPage, completed: filterValue, startDate: startDate, endDate: endDate, filterText: filterText }],
        queryFn: ({ signal, queryKey }) => fetchAllCorretedEmails({ signal, ...queryKey[1], ...queryKey[2], ...queryKey[3], ...queryKey[4], ...queryKey[5] }),
        staleTime: 30000, // half minute
        gcTime: 1000
    });
    function prepeareEditModal(entry, xmlFlag = false) {
        const entry_copy = entry;
        const wagonWeights = (entry.weight.replace(/\[|\]/g, '')).split(',');
        const wagonNr = (entry.transport_nr.replace(/\[|\]/g, '')).split(',');
        const wagonProduct = (entry.product.replace(/\[|\]/g, '')).split(',');
        const wagonMrn = (entry.mrn.replace(/\[|\]/g, '')).split(',');

        const wagonsWeightsIsArray = Array.isArray(wagonWeights);
        let wagonsLoader = { wagons: [] };
        if (wagonsWeightsIsArray) {

            for (let index = 0; index < wagonWeights.length; index++) {
                const buffer = { wagon_nr: wagonNr[index], gewicht: wagonWeights[index], produkt: wagonProduct[index], mrn: wagonMrn[index] }
                wagonsLoader = { wagons: [...wagonsLoader.wagons, buffer] }
            }
        }
        entry_copy.wagons = wagonsLoader.wagons;
        entry_copy.xmlFlag = xmlFlag
        setFormData(entry_copy)
    }
    useEffect(() => {
        if (formData !== null) {
            setShowModal(true)
        }
    }, [formData]);
    function HistoryTable(dataForms) {
        if (isPendingForms) {
            return <LoadingIndicator />;
        }
        if (isErrorForms) {
            return (
                <ErrorBlock
                    title="An error occurred"
                    message={errorForms.info?.message || 'Failed to fetch forms. Check your internet access or backend server might be offline'}
                />
            );
        }
        if (dataForms) {
            return (
                <>
                    <Table striped bordered hover size='sm' responsive="sm">
                        <thead>
                            <tr>
                                <th><h5><b>Absender</b></h5></th>
                                <th><h5><b>Empfänger</b></h5></th>
                                <th><h5><b>Vermerke</b></h5></th>
                                <th><h5><b>Lieferort</b></h5></th>
                                <th><h6><b>Datum geladen</b></h6></th>
                                <th><h6><b>Auftrags Code</b></h6></th>
                                <th><h6><b>Verwiegung Bahnhof Code</b></h6></th>
                                <th><h6><b>Zulaufnummer</b></h6></th>
                                <th><h6><b>Lieferantnummer</b></h6></th>
                                <th><h6><b>AC Info</b></h6></th>
                                <th><h5><b>Erstellt am</b></h5></th>
                                <th><h5><b>Auftragsnummer</b></h5></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={12} style={{ height: '1px', backgroundColor: '#212026' }}></td>
                            </tr>
                            {dataForms.corrected_emails.results.map((entry) => {
                                const wagonWeights = (entry.weight.replace(/\[|\]/g, '')).split(',');
                                const wagonNr = (entry.transport_nr.replace(/\[|\]/g, '')).split(',');
                                const wagonProduct = (entry.product.replace(/\[|\]/g, '')).split(',');
                                const wagonMrn = (entry.mrn.replace(/\[|\]/g, '')).split(',');
                                const dateBuffer = new Date(entry.date_load)
                                const formatedDate = `${dateBuffer.getDate()}/${dateBuffer.getMonth() + 1}/${dateBuffer.getFullYear()}, ${String(dateBuffer.getHours()).padStart(2, '0')}:${String(dateBuffer.getMinutes()).padStart(2, '0')}:${String(dateBuffer.getSeconds()).padStart(2, '0')}`;

                                const renderWagons = () => {
                                    return wagonWeights.map((weight, index) => (
                                        <div key={index} >
                                            <button><b>Wagonummer:</b> {wagonNr[index]} <b>:</b> <i>{wagonProduct[index]}</i> <b>:</b> {weight} <b>(kg)</b> {wagonMrn[index] !== '' && <> <b>Mrn:</b> {wagonMrn[index]}</>}</button>

                                        </div>
                                    ));
                                };

                                return (
                                    <Fragment key={entry.id}>
                                        <tr key={entry.id + '-1'}>
                                            <td>{entry.sender}</td>
                                            <td>{entry.counterpart}</td>
                                            <td>{entry.remarks}</td>
                                            <td>{entry.deliver_place}</td>
                                            <td>{formatedDate}</td>
                                            <td>{entry.auftrags_code}</td>
                                            <td>{entry.weighing_station}</td>
                                            <td>{entry.zulauf_nr}</td>
                                            <td>{entry.lieferant_nr}</td>
                                            <td>
                                                <b>Versandbahnhof: </b>{entry.ac_info.versandbanhof} - Nr: {entry.station_code} <br />
                                                <b>Empfangsbahnhof: </b>{entry.ac_info.empfangsbahnhof} - Nr: {entry.ac_info.ebf_nr}
                                            </td>
                                            <td>{entry.created_at}</td>
                                            <td>{entry.cu}</td>
                                        </tr>
                                        <tr key={entry.id + '-2'}>
                                            <td><b>Wagons:</b></td>
                                            <td colSpan={8}>{renderWagons()}</td>

                                            <td colSpan={3} >
                                                <ButtonGroup >
                                                    {entry.status === 0 &&
                                                        <>
                                                            <Button variant='outline-primary' onClick={() => handleFormUpdateModal(entry)} >Formular bearbeiten</Button>
                                                            {!mutationSubmitPending ? <Button variant='primary' onClick={() => handleFormSubmit(entry.id)} >Formular absenden</Button> : <LoadingIndicator />}
                                                            <Button variant="danger" onClick={() => handleDeleteForm(entry.id)}>Formular abbrechen</Button>
                                                        </>
                                                    }
                                                    {entry.status === 1 &&
                                                        <>
                                                            <Button variant="warning" disabled={true}>Das Formular wird übermittelt.</Button>
                                                        </>
                                                    }
                                                    {entry.status === 2 &&
                                                        < >
                                                            <Button variant="success" disabled={true}>Formular übermittelt</Button>
                                                            <Button variant="outline-primary" onClick={() => handleXmlFormUpdateModal(entry)}>Aktualisieren (TPOU)</Button>
                                                            <Button variant="outline-danger" onClick={() => handleXmlCancelMutation(entry.id)} >Stornieren (TPOC)</Button>
                                                        </>
                                                    }
                                                    {entry.status === 3 &&
                                                        < >
                                                            <Button variant="warning" disabled={true}>Auftrag aktualisieren...</Button>
                                                        </>
                                                    }
                                                    {entry.status === 4 &&
                                                        < >
                                                            <Button variant="success" disabled={true}>Erfolgreich aktualisiert</Button>
                                                            <Button variant="outline-primary" onClick={() => handleXmlFormUpdateModal(entry)}>Auftrag Aktualisieren (TPOU)</Button>
                                                            <Button variant="outline-danger" onClick={() => handleXmlCancelMutation(entry.id)} >Auftrag Stornieren (TPOC)</Button>
                                                        </>
                                                    }
                                                    {entry.status === 5 &&
                                                        <>
                                                            <Button variant="outline-danger" disabled={true}>Auftrag Stornieren...</Button>
                                                        </>
                                                    }
                                                    {entry.status === 6 &&
                                                        < >
                                                            <Button variant="danger" disabled={true}>Auftrag Storniert</Button>
                                                            {!mutationSubmitPending ? <Button variant='primary' onClick={() => handleFormSubmit(entry.id)} >Formular absenden</Button> : <LoadingIndicator />}
                                                        </>
                                                    }
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={12} style={{ height: '15px', backgroundColor: '#6f6f70' }}></td>
                                        </tr>
                                    </Fragment>)
                            })}

                        </tbody>
                    </Table >

                    <CustomPagination reqResponse={dataForms.corrected_emails} setCurrentPage={setCurrentPage} currentPage={currentPage} />

                </>
            )
        }
    }


    return (
        <>
            <AlertDismissible show={showAlert} setShow={setShowAlert} variant={alertVariant} pageContext={pageContext} />
            <div>
                <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label ><b>Filter</b></Form.Label>
                    <Form.Control type="text" value={filterText} onChange={handleFilterInputChange} placeholder="Type to filter" style={{ border: '3px solid black' }} />
                </Form.Group>
            </div>
            <div>
                <ButtonGroup>
                    {filter.map((radio, idx) => (
                        <ToggleButton
                            key={idx}
                            id={`radio-${idx}`}
                            type="radio"
                            variant={'outline-primary'}
                            name="radio"
                            value={radio.value}
                            checked={filterValue === radio.value}
                            onChange={(e) => setFilterValue(e.currentTarget.value)}
                        >
                            {radio.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup>
                <b> From: </b>
                <DatePicker showIcon selectsStart maxDate={new Date()} dateFormat="dd/MM/yyyy" startDate={startDate}
                    endDate={endDate} selected={startDate} onChange={(date) => setStartDate(date)} />
                <b>To: </b>
                <DatePicker showIcon startDate={startDate}
                    endDate={endDate} selectsEnd maxDate={new Date()} dateFormat="dd/MM/yyyy" selected={endDate} onChange={(date) => setEndDate(date)} />
                <Button variant="outline-success" onClick={handleDownloadExcel}><img src='/excel.png' alt='download Excel' /> Aufträge herunterladen</Button>
            </div>
            <br />
            <HistoryModal updateMutate={handleFormUpdateMutation} xmlUpdateMutate={handleXmlUpdateMutation} showModal={showModal} setShowModal={setShowModal} formData={formData} setFormData={setFormData} />
            {HistoryTable(dataForms)}
        </>
    );
}